<template>
  <v-container fluid v-if="customer">
    <snackbar-queue v-model="messages"></snackbar-queue>
    <div class="text-center">
      <h1>EMMA CSV Importer for {{ customer.name }}</h1>
      <a style="float:right" target="_blank"
        href="https://chillimetrix.alturos.com/confluence/display/EMMA/CSV+Importer+Tool">
        <v-icon>mdi-information-outline</v-icon><br>Confluence Docu
      </a>
      <p>
        tool to import multiple users into a braze app group with a csv file
      </p>
    </div>
    <div>
      <p v-if="isInitial || isLoading">
        Please note the following points:
      <ul>
        <li>Separate your csv file with semicolons <b>";"</b> and NOT comma ","</li>
        <li>There must be a column <b>email</b> in the csv file</li>
        <li>Values for email must have correct syntax (mail@example.com)</li>
        <li>Date of birth format should be <b>dd.mm.yyyy (31.05.1991)</b> or <b>yyyy-mm-dd (1991-05-31)</b></li>
        <li>Gender must be <b>F</b> (female) / <b>M</b> (male) / O (other) / N (not applicable) / P (prefered not to
          say) or an empty field</li>
        <li>Values for language must be an ISO-639 two-letter code (e.g., <b>de</b>) or the english language name (e.g.,
          german)</li>
        <li>Values for country must be an ISO-3166-1 alpha -2 standard code (e.g., <b>DE</b> / AT / NL)</li>
        <li>Imported users get set a custom attribute (csv_importer_tool) that references the import. The value for the
          custom attribute can be chosen later on</li>
        <li>Objects can be added with single quotes (e.g., <strong>{'add': ['value1', 'value2']}</strong></li>
      </ul>
      </p>
      <!--UPLOAD FORM-->
      <form enctype="multipart/form-data" novalidate v-if="isInitial || isLoading">
        <div class="dropbox">
          <input type="file" name="uploadField" :disabled="isLoading" accept=".csv" class="input-file" id="uploadField"
            @change="filesChange($event.target.name, $event.target.files)" />
          <p v-if="isInitial || isFailed">
            Drag your csv file here to begin<br />
            or click to browse
          </p>
          <p v-if="isLoading">
            Uploading file...
          </p>
        </div>
      </form>
      <div class="text-center" v-if="isLoading">
        <v-progress-circular :rotate="360" :size="100" :width="15" :value="progress" color="teal">
          {{ progress }}%
        </v-progress-circular>
      </div>
      <!--Form to Select Attributes-->
      <div v-if="isConfirmAttributes">
        <h2>Recognized {{ default_attributes.length + custom_attributes.length }} Attributes</h2>
        <div>
          <b> {{ default_attributes.length }} default attributes:</b>
          <ul v-for="att in default_attributes" :key="att">
            <li v-if="att != 'external_id'">{{ att }}</li>
          </ul>
        </div>
        <div>
          <b> {{ custom_attributes.length }} custom attributes:</b>
          <ul v-for="att in custom_attributes" :key="att">
            <li>{{ att }}</li>
          </ul>
        </div>
        <hr style="margin: 20px">
        <h2>Found {{ amount_new_users + amount_existing_users }} Users</h2>
        <template v-if="amount_new_users > 0">
          <br />
          <p>
            <strong>{{ amount_new_users }} new users</strong> will be imported
            with all attributes from the upload.
          </p>
          <div v-if="amount_existing_users == 0" style="text-align: center">
            <v-btn @click="skipConfirmAttributes()" color="green">Next</v-btn>
          </div>
        </template>
        <template v-if="amount_existing_users > 0 && isConfirmAttributes">
          <p>
            <strong>{{ amount_existing_users }} existing users</strong> in app group
            {{ customer.name }}: Attributes will be imported if not yet present.
          </p>
          <div class="rcorners">
            <p>
              <b>If some attributes are already set for a user in Braze, which attributes should be
                overwritten by the import?</b>
            </p>
            <div v-for="attribute in attributes" :key="attribute">
              <input type="checkbox" :id="attribute" :value="attribute" class="css-checkbox mr-2"
                v-if="attribute != 'external_id'" />
              <label :for="attribute" class="css-label" v-if="attribute != 'external_id'">{{ attribute }}</label>
              <template v-if="attribute === 'email_subscribe'">
                <v-tooltip top slot="append">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
                  </template>
                  <span> For overwriting this attribute, select <strong>both</strong> the 'email_subscribe' checkbox and
                    the
                    suitable email_subscribe stati.
                  </span>
                </v-tooltip>
                <div v-for="status in email_subscribe_stati" :key="status">
                  <input type="checkbox" :id="status" :value="status" class="css-checkbox mr-2 ml-4" />
                  <label :for="status" class="css-label">existing <strong>{{ status }}</strong> users</label>
                </div>

              </template>
            </div>
            <v-btn @click="uncheckAll()" class="ma-1" color="grey">Uncheck All</v-btn>
            <v-btn @click="checkAll()" class="ma-1" color="grey">Check All</v-btn>
            <v-btn @click="confirmAttributes()" class="ma-1" color="green">Next</v-btn>
          </div>
        </template>
      </div>
      <!--Preview Changes-->
      <div v-if="isConfirmUpload || isWaitingConfirmation">
        <h2>Confirm Upload.</h2>
        <div class="rcorners">
          <p>
            You are about to import
            <b>{{ amount_new_users }} new users and modify
              {{ amount_existing_users }} users</b>. To identify them later set a value for the custom attribute
            <b>csv_importer_tool</b>.
          </p>
          <b>csv_importer_tool:</b>
          <input type="text" id="csv_importer_tool" name="csv_importer_tool" v-model="csv_importer_tool" />
        </div>
        <div style="margin-top: 20px" class="rcorners">
          <p>
            Choose a name that will be used to reference this import in the logs.
          </p>
          <b>Import Name:</b>
          <input type="text" id="import_name" name="import_name" v-model="import_name" />
        </div>
        <template v-if="amount_existing_users > 0">
          <h2 class="mt-2">Preview Existing Users:</h2>
          <div style="text-align: center">
            <input type="number" min="1" :max="existing_users_braze.length" step="1" v-model="user_index" />
            / {{ existing_users_braze.length }} ({{ amount_existing_users }} total)
            <button class="navigation"
              @click="user_index <= existing_users_braze.length - 1 ? user_index++ : ''">&gt;</button>
            <button class="navigation" @click="user_index > 1 ? user_index-- : ''">&lt;</button>
          </div>
          <table class="overview">
            <tr>
              <th>Attribute</th>
              <th>Old</th>
              <th>New</th>
            </tr>
            <tr v-for="attribute in attributes" :key="attribute">
              <td :style="{ opacity: attribute == 'external_id' ? 0.5 : 1 }">{{ attribute }}</td>
              <td
                :style="{ color: existing_users_braze[getUserIndex][attribute] == merged_users[getUserIndex][attribute] ? '' : 'red', opacity: attribute == 'external_id' ? 0.5 : 1 }">
                {{
                  existing_users_braze[getUserIndex][attribute]
                    ? existing_users_braze[getUserIndex][attribute]
                    : "-"
                }}
              </td>
              <td
                :style="{ color: existing_users_braze[getUserIndex][attribute] == merged_users[getUserIndex][attribute] ? '' : 'green', opacity: attribute == 'external_id' ? 0.5 : 1 }">
                {{
                  merged_users[getUserIndex][attribute]
                    ? merged_users[getUserIndex][attribute]
                    : "-"
                }}
              </td>
            </tr>
          </table>
        </template>
        <template v-if="amount_new_users > 0">
          <h2 class="mt-2">Preview New Users:</h2>
          <div style="text-align: center">
            <input type="number" min="1" :max="new_users.length" step="1" v-model="user_index2" />
            / {{ new_users.length }} ({{ amount_new_users }} total)
            <button class="navigation" @click="user_index2 <= new_users.length - 1 ? user_index2++ : ''">&gt;</button>
            <button class="navigation" @click="user_index2 > 1 ? user_index2-- : ''">&lt;</button>
          </div>
          <table class="overview">
            <tr>
              <th>Attribute</th>
              <th>Old</th>
              <th>New</th>
            </tr>

            <tr v-for="attribute in attributes" :key="attribute">
              <template v-if="attribute != 'external_id'">
                <td>{{ attribute }}</td>
                <td>-</td>
                <td>
                  {{
                    new_users[getUserIndex2][attribute]
                      ? new_users[getUserIndex2][attribute]
                      : "-"
                  }}
                </td>
              </template>
            </tr>
          </table>
        </template>
        <div style="text-align: center">
          <v-btn @click="backConfirmAttributes()" class="ma-2" v-if="!isWaitingConfirmation" color="blue">Back</v-btn>
          <v-btn @click="finishImport()" class="ma-2" color="green" v-if="!isWaitingConfirmation">Import {{
            amount_existing_users
          }} existing users and
            {{ amount_new_users }} new users into app group
            {{ customer.name }}</v-btn>
          <div class="text-center" v-if="isWaitingConfirmation">
            <v-progress-circular :rotate="360" :size="100" :width="15" :value="progress" color="teal">
              {{ progress }}%
            </v-progress-circular>
          </div>
        </div>
      </div>
      <!--SUCCESS-->
      <div v-if="isFinished">
        <h2>Users successfully imported.</h2>
        <p>{{ success_message }}</p>
        <p v-if="minor_errors && minor_errors.length > 0">
          <b>Errors occured for some users:</b>
        <ul>
          <li v-for="err in minor_errors" :key="err.index">
            <b>User:</b> {{ err.email }} <b>-></b> {{ err.type }}
          </li>
        </ul>
        </p>
        <v-btn @click="reset()" color="green">Okay</v-btn>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Upload failed.</h2>
        <p><b>Error:</b> {{ error }}</p>
        <v-btn @click="reset()" color="green">Okay</v-btn>
      </div>
      <v-btn v-if="!(isInitial || isFinished || isFailed)" @click="reset()" color="red" class="ma-2">Cancel</v-btn>
    </div>
    <!-- LOGS -->
    <div class="mt-5">
      <template v-if="isInitial || isLoading">
        <h2>Logs for {{ customer.name }}</h2>
        <v-btn @click="loadLogs()" color="blue" style="float:right">Refresh Logs</v-btn>
        <v-expansion-panels multiple focusable inset>
          <v-expansion-panel v-for="(log, name) in logs" :key="name">
            <v-expansion-panel-header>
              <div>
                <span><b>Name: </b>{{ name.split(":||:")[0] }}</span>
                <span style="float:right"><b>Time: </b>{{ name.split(":||:")[1] }}</span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panel v-for="(user, i) in log" :key="i">
                <v-expansion-panel-header>
                  <div>{{ user.email ? user.email : "-" }}</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table class="overview" style="width: auto">
                    <tr>
                      <th>Attribute</th>
                      <th>Value</th>
                    </tr>
                    <tr v-for="(value, att) in user" :key="att">
                      <td>{{ att }}</td>
                      <td>
                        {{ value? value: "-" }}
                      </td>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <div v-if="logs_minor_errors[name]" style="margin: 20px">
                <b>Minor Errors:</b>
                <ul>
                  <li v-for="err in logs_minor_errors[name]" :key="err.index">
                    <b>User:</b> {{ err.email }} <b>-></b> {{ err.type }}
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </div>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import braze_attributes from "../../assets/braze_attributes.js";
import SnackBarQueue from "../helpers/SnackBarQueue";
const INITAL = 0,
  LOADING = 1,
  CONFIRM_ATTRIBUTES = 2,
  CONFIRM_UPLOAD = 3,
  WAITING_CONFIRMATION = 4,
  FINISHED = 5,
  FAILED = 6;

export default {
  props: ["mandator"],
  components: {
    "snackbar-queue": SnackBarQueue,
  },
  data() {
    return {
      messages: [],
      error: null,
      minor_errors: null,
      currentStatus: INITAL,
      attributes: null,
      default_attributes: [],
      custom_attributes: [],
      attribute_selection: {},
      new_users: null,
      existing_users_braze: null,
      existing_users_import: null,
      amount_existing_users: 0,
      amount_new_users: 0,
      import_id: null,
      merged_users: [],
      user_index: 1,
      user_index2: 1,
      success_message: null,
      progress: 0,
      pollJob: null,
      import_name: null,
      csv_importer_tool: null,
      logs: null,
      logs_minor_errors: null,
      email_subscribe_stati: ["opted_in", "subscribed", "unsubscribed"],
      overwrite_email_subscribe: {},
    };
  },
  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },

    isInitial() {
      return this.currentStatus === INITAL;
    },
    isLoading() {
      return this.currentStatus === LOADING;
    },
    isConfirmAttributes() {
      return this.currentStatus === CONFIRM_ATTRIBUTES;
    },
    isConfirmUpload() {
      return this.currentStatus === CONFIRM_UPLOAD;
    },
    isWaitingConfirmation() {
      return this.currentStatus === WAITING_CONFIRMATION;
    },
    isFinished() {
      return this.currentStatus === FINISHED;
    },
    isFailed() {
      return this.currentStatus === FAILED;
    },
    getUserIndex() {
      if (this.user_index > this.amount_existing_users) {
        return this.amount_existing_users - 1;
      } else if (this.user_index <= 0) {
        return 0;
      } else {
        return this.user_index - 1;
      }
    },
    getUserIndex2() {
      if (this.user_index2 > this.amount_new_users) {
        return this.amount_new_users - 1;
      } else if (this.user_index2 <= 0) {
        return 0;
      } else {
        return this.user_index2 - 1;
      }
    },
  },
  watch: {
    // update fields to current time when status changes
    currentStatus: function (val) {
      if (this.currentStatus === CONFIRM_UPLOAD) {
        let current_time = moment().tz("Europe/Vienna").format().split("+")[0];
        this.import_name = this.csv_importer_tool = current_time;
      }
    },
  },
  methods: {
    // reset page to initial state
    reset() {
      (this.error = null),
        (this.minor_errors = null),
        (this.currentStatus = INITAL),
        (this.attributes = null),
        (this.new_users = null),
        (this.existing_users_braze = null),
        (this.existing_users_import = null),
        (this.amount_existing_users = 0),
        (this.amount_new_users = 0),
        (this.import_id = null),
        (this.merged_users = []),
        (this.user_index = 1),
        (this.user_index2 = 1),
        (this.success_message = null);
      this.progress = 0;
      this.default_attributes = [];
      this.custom_attributes = [];
      this.attribute_selection = {};
      clearInterval(this.pollJob);
    },
    // handle file upload
    filesChange(fieldName, fileList) {
      try {
        if (!fileList) {
          alert("Choose a file first!");
          return;
        }
        if (fileList.length != 1) {
          alert("Only choose 1 file!");
          return;
        }
        this.currentStatus = LOADING;
        const file = fileList[0];

        const form = new FormData();
        form.append("csv_file", file);

        this.uploadToServer(form);
      } catch (err) {
        this.error = err.response ? err.response.data.message : err.message;
        this.currentStatus = FAILED;
      }
    },
    // upload csv file to the server and get new and existing users in braze
    uploadToServer(form) {
      try {
        const url = `/customers/upload-csv`;
        this.axios
          .post(url, form, {
            params: {
              mandator: this.customer.mandator,
            },
          })
          .then((res) => {
            this.pollJob = setInterval(() => {
              this.axios
                .get(`/customers/import-job/`, {
                  params: {
                    jobId: res.data.jobId,
                  },
                })
                .then((res) => {
                  // job finished AND data is returned AND this is the first response
                  if (
                    res.data.progress == 100 &&
                    res.data.existing_users_import &&
                    this.attributes === null
                  ) {
                    clearInterval(this.pollJob);
                    this.existing_users_braze = res.data.existing_users_braze; // list of already existing users in braze
                    this.new_users = res.data.new_users; // list of new users
                    this.existing_users_import = res.data.existing_users_import; // list of existing users from the import
                    this.attributes = res.data.attributes; // columns in the csv file
                    this.amount_existing_users = res.data.amount_existing_users;
                    this.amount_new_users = res.data.amount_new_users;
                    this.import_id = res.data.import_id; // id to later reference the upload

                    // split attributes in default and custom attributes
                    for (let att of this.attributes) {
                      if (braze_attributes.includes(att)) {
                        if (att !== "external_id")
                          this.default_attributes.push(att);
                      } else {
                        this.custom_attributes.push(att);
                      }
                    }

                    this.currentStatus = CONFIRM_ATTRIBUTES;
                    this.progress = 0;
                  } else {
                    this.progress = res.data.progress;
                  }
                })
                .catch((err) => {
                  clearInterval(this.pollJob);
                  this.error = err.response
                    ? err.response.data.message
                    : err.message;
                  this.currentStatus = FAILED;
                });
            }, 1000);
          })
          .catch((err) => {
            this.error = err.response ? err.response.data.message : err.message;
            this.currentStatus = FAILED;
          });
      } catch (err) {
        this.error = err.response ? err.response.data.message : err.message;
        this.currentStatus = FAILED;
      }
    },
    // merge existing and import users based on selected attributes
    confirmAttributes(fieldName) {
      try {
        let checkboxes = document.getElementsByClassName("css-checkbox");
        let checkedAttributes = [];
        let i;
        for (i = 0; i < checkboxes.length; i++) {
          if (this.email_subscribe_stati.includes(checkboxes[i].id))
            this.overwrite_email_subscribe[checkboxes[i].id] =
              checkboxes[i].checked;
          else if (checkboxes[i].checked)
            checkedAttributes.push(checkboxes[i].value);
        }

        //merge attributes of conflicting users
        for (i = 0; i < this.existing_users_braze.length; i++) {
          let user = {};
          for (let j = 0; j < this.attributes.length; j++) {
            if (checkedAttributes.includes(this.attributes[j])) {
              this.attribute_selection[this.attributes[j]] = true;
              if (this.existing_users_import[i][this.attributes[j]] != null) {
                // only overwrite attribute if value is != null
                if (this.attributes[j] === "email_subscribe") {
                  if (
                    this.overwrite_email_subscribe[
                    this.existing_users_braze[i][this.attributes[j]]
                    ]
                  )
                    user[this.attributes[j]] =
                      this.existing_users_import[i][this.attributes[j]];
                  else
                    user[this.attributes[j]] =
                      this.existing_users_braze[i][this.attributes[j]];
                } else {
                  user[this.attributes[j]] =
                    this.existing_users_import[i][this.attributes[j]];
                }
              } else if (
                this.existing_users_braze[i][this.attributes[j]] != null
              ) {
                user[this.attributes[j]] =
                  this.existing_users_braze[i][this.attributes[j]];
              }
            } else {
              this.attribute_selection[this.attributes[j]] = false;
              if (this.existing_users_braze[i][this.attributes[j]] != null) {
                user[this.attributes[j]] =
                  this.existing_users_braze[i][this.attributes[j]];
              } else if (
                this.existing_users_import[i][this.attributes[j]] != null
              ) {
                user[this.attributes[j]] =
                  this.existing_users_import[i][this.attributes[j]];
              }
            }
          }
          this.merged_users.push(user);
        }
        this.currentStatus = CONFIRM_UPLOAD;
      } catch (err) {
        this.error = err.response ? err.response.data.message : err.message;
        this.currentStatus = FAILED;
      }
    },
    // skip confirm attributes stage when there are no conflicting users
    skipConfirmAttributes() {
      this.currentStatus = CONFIRM_UPLOAD;
    },
    // goes back to confirm attributes stage
    backConfirmAttributes() {
      this.currentStatus = CONFIRM_ATTRIBUTES;
      this.merged_users = [];
    },
    checkAll() {
      let checkboxes = document.getElementsByClassName("css-checkbox");
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    },
    uncheckAll() {
      let checkboxes = document.getElementsByClassName("css-checkbox");
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    },
    // import the users into braze
    finishImport() {
      try {
        const url = `/customers/mass-import-users`;
        this.currentStatus = WAITING_CONFIRMATION;
        this.axios
          .post(url, {
            mandator: this.customer.mandator,
            import_id: this.import_id,
            attribute_selection: this.attribute_selection,
            overwrite_email_subscribe: this.overwrite_email_subscribe,
            csv_importer_tool: this.csv_importer_tool,
            import_name: this.import_name,
          })
          .then((res) => {
            this.pollJob = setInterval(() => {
              this.axios
                .get(`/customers/import-job/`, {
                  params: {
                    jobId: res.data.jobId,
                  },
                })
                .then((res) => {
                  if (res.data.progress == 100 && res.data.message) {
                    clearInterval(this.pollJob);
                    this.success_message = res.data.message;
                    this.minor_errors = res.data.minor_errors;
                    this.currentStatus = FINISHED;
                    this.progress = 0;
                  } else {
                    this.progress = res.data.progress;
                  }
                })
                .catch((err) => {
                  clearInterval(this.pollJob);
                  this.error = err.response
                    ? err.response.data.message
                    : err.message;
                  this.currentStatus = FAILED;
                });
            }, 1000);
          })
          .catch((err) => {
            this.error = err.response ? err.response.data.message : err.message;
            this.currentStatus = FAILED;
          });
      } catch (err) {
        this.error = err.response ? err.response.data.message : err.message;
        this.currentStatus = FAILED;
      }
    },
    created() {
      this.reset();
    },
    // load logs which are saved in redis
    loadLogs() {
      const _this = this;
      this.axios
        .get(`/customers/csv-importer-logs`, {
          params: {
            mandator: this.customer.mandator,
          },
        })
        .then((res) => {
          let data = res.data.logs;
          _this.logs = {};
          _this.logs_minor_errors = {};
          for (let i in data) {
            let arr = data[i].split(":||:");
            let log = arr[0];
            let errors = arr[1];
            if (log.length > 0) _this.logs[i] = JSON.parse(log);
            if (errors.length > 0)
              _this.logs_minor_errors[i] = JSON.parse(errors);
          }
          // sort imports based on time
          let ordered = {};
          Object.keys(_this.logs)
            .sort((a, b) => {
              let date_a = a.split(":||:")[1];
              let date_b = b.split(":||:")[1];
              return new Date(date_b) - new Date(date_a);
            })
            .forEach(function (key) {
              ordered[key] = _this.logs[key];
            });
          _this.logs = ordered;
          this.messages.push("Logs loaded!");
        })
        .catch((err) => {
          _this.logs = this.logs_minor_errors = {};
          _this.error = err.response ? err.response.data.message : err.message;
          _this.currentStatus = FAILED;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.customer {
  position: relative;
}

.h1 {
  text-align: center;
}

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.rcorners {
  border-radius: 25px;
  border: 2px solid #73ad21;
  padding: 20px;
}

#csv_importer_tool,
#import_name {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  color: white;
  text-align: center;
  background-color: cornflowerblue;
}

.overview {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.overview td,
.overview th {
  border: 1px solid #ddd;
  padding: 8px;
}

.overview tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overview tr:hover {
  background-color: #ddd;
}

.overview th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.navigation {
  background-color: #e7e7e7;
  color: black;
  /* Gray */
  float: right;
  text-align: center;
  width: 50px;
}

.v-progress-circular {
  margin: 20px;
}
</style>
