<template>
  <v-app id="app">
    <!-- Toolbar -->
    <v-app-bar app color="#0097A7" dense dark>
      <v-toolbar-title>JEMMA ADMIN 2.1.6</v-toolbar-title>
      <button ref="shiftFocus" />
      <v-spacer></v-spacer>

      <!-- Profile -->
      <v-menu offset-y close-on-click v-if="haveUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon size="32px">mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ user && user.profile ? user.profile.name : "" }}</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-title style="color: red">LOGOUT</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app permanent width="175">
      <v-row class="fill-container">
        <v-col class="fill-navigation">
          <br />
          <v-card :class="active === 'dashboard' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat
            @click="setActive('dashboard')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-view-dashboard</v-icon><br /><br />
              <v-label>Dashboard</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'contentful' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat
            @click="setActive('contentful')">
            <div class="navigation-item-wrapper">
              <div style="
                    height: 32px;
                    width: 32px;
                    margin-left: auto;
                    margin-right: auto;
                  ">
                <img style="height: 100%; width: 100%; object-fit: contain" src="./assets/icon-contentful.svg" />
              </div>
              <br />
              <v-label>Contentful</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'journeys' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat
            @click="setActive('journeys')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-map-marker-path</v-icon><br /><br />
              <v-label>JAMES Journeys</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'skiline' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat
            @click="setActive('skiline')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-terrain</v-icon><br /><br />
              <v-label>Skiline</v-label>
            </div>
          </v-card>

          <br />

          <v-card :class="active === 'braze' ? 'navigation-menu-item-active' : 'navigation-menu-item'" flat
            @click="setActive('braze')">
            <div class="navigation-item-wrapper">
              <v-icon size="32px" color="#455A64">mdi-account-group</v-icon><br /><br />
              <v-label>Braze</v-label>
            </div>
          </v-card>
        </v-col>
        <v-divider vertical class="vertical-divider"></v-divider>
      </v-row>
    </v-navigation-drawer>
    <!-- Content -->
    <v-main class="content">
      <v-container class="container" fluid>
        <v-row class="fill-container">
          <v-col class="fill-container">
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import jwt from "jsonwebtoken";
export default Vue.extend({
  name: "App",
  data() {
    return {};
  },
  computed: {
    haveUser() {
      return Vue.mainOidc.isAuthenticated
    },
    active() {
      return this.$store.getters.activeMenuItem;
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
  },
  methods: {
    logout() {
      Vue.mainOidc.mgr.removeUser()
      this.$router.push("/login")
    },

    setActive(item) {
      this.$store.commit("setActiveMenuItem", item);
      if (this.$router.currentRoute.fullPath !== `/${item}`) {
        this.$router.push(`/${item}`);
      }
      //shift focus otherwise the background of the selected icon stays grey
      this.$nextTick(function () {
        this.$refs.shiftFocus.focus();
      });
    },
  },

  created() {
    const path =
      this.$router.currentRoute.fullPath.length > 1
        ? this.$router.currentRoute.fullPath.substring(1).split("/")[0]
        : "dashboard";
    this.$store.commit("setActiveMenuItem", path);
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.config &&
          !error.response.config.__isRetryRequest
        ) {
          // if you ever get an unauthorized, logout the user
          this.$cookies.remove("access_token");
          this.$cookies.remove("refresh_token");
          // you can also redirect to /login if needed !
          this.$router.push("/login");
        }
        return Promise.reject(error);
      }
    );
    // intercept requests and check if access Token is present
    this.axios.interceptors.request.use(
      (config) => {
        console.log('interceptor', config)
        config.headers = {
          Authorization: 'Bearer ' + Vue.mainOidc.accessToken
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  },
});
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.v-navigation-drawer {
  background-color: #cfd8dc !important;
}

.navigation-item-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigation-menu-item {
  width: 100%;
  background-color: transparent !important;
  padding: 3px !important;
  margin: 3px !important;
  text-align: center;
}

.navigation-menu-item-active {
  width: 100%;
  border-top: 2px solid #90a4ae !important;
  border-left: 2px solid #90a4ae !important;
  border-bottom: 2px solid #90a4ae !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  background-color: #ffffff !important;
  padding: 2px !important;
  margin: 2px !important;
  text-align: center;
}

.vertical-divider {
  width: 2px !important;
  min-width: 2px !important;
  background-color: #90a4ae;
}

.horizontal-divider {
  height: 1px !important;
  min-height: 1px !important;
  background-color: #90a4ae;
  margin-top: 20px;
}

.horizontal-divider-dense {
  height: 1px !important;
  min-height: 1px !important;
  background-color: #90a4ae;
  margin-top: 0px;
}

.content {
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
}

.container {
  height: 100%;
  padding: 0px !important;
}

.fill-navigation {
  height: 100%;
  width: 100%;
  padding: 0px 0px 10px 10px !important;
  margin: 0px 0px 10px 10px !important;
}

.fill-container {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.primary-button {
  height: 48px !important;
  background: linear-gradient(0deg, #0097a7 0%, #00acc1 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-size: 13pt !important;
  text-transform: uppercase !important;
  letter-spacing: 0px !important;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: none !important;
}

.red-button {
  height: 48px !important;
  background: linear-gradient(0deg, #b71c1c 0%, #d32f2f 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-size: 13pt !important;
  text-transform: uppercase !important;
  letter-spacing: 0px !important;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: none !important;
}

h1 {
  font-weight: normal;
  font-size: 22pt;
}

h2 {
  font-weight: normal;
  font-size: 18pt;
}

.sub-title {
  font-weight: normal;
  font-size: 10pt;
}

.v-label {
  font-size: 14pt !important;
}

.v-tabs {
  padding-left: 10px;
  padding-top: 30px;
  margin-left: 10px;
}

.v-tab {
  font-size: 13pt !important;
  font-weight: bold !important;
  letter-spacing: 0.1 !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}
</style>
